import { useState, useRef, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
// import MainLogo from "../../assets/image/main-logo.png";
// import MainLogo from "../../assets/image/jgc-new-logo.png";
import MainLogo from "../../assets/image/jgc_logo.png";
import SewingIcon from "../../assets/image/sewing-icon.png";
import LocationIcon from "../../assets/image/location-icon.png";
import { BsCheckLg, BsFillCalendarWeekFill } from "react-icons/bs";
import AshokImg from "../../assets/image/ashok-ji-pic.jpg";
import DeepakImg from "../../assets/image/deepak-ji-pic.jpeg";
import AboutImg from "../../assets/image/banner-img.jpeg";
import moment from "moment";
import CalenderIcon from "../../assets/image/calendar.png";
// import Carousel from "react-elastic-carousel";
import Carousel from 'react-bootstrap/Carousel';
import Table from 'react-bootstrap/Table';
// import ExampleCarouselImage from 'components/ExampleCarouselImage';
import Item from "./Item";
import UpcomingEvents from "../../assets/image/upcoming-event-img.png";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { ModalBody, Spinner } from "react-bootstrap";
import "../../../src/stylecss/mainpage.css";
import Swiper from "swiper";
import "swiper/css";
// import Swiper from 'swiper/swiper-bundle.min.css';
// import 'swiper/swiper-bundle.min.css';
import WOW from "wowjs";
import "animate.css/animate.min.css";
import ImgDeepak from "../../assets/image/IMG-deepak.jpg";
import ImgAshok from "../../assets/image/IMG-Ashok.jpg";
import ImgChandiram from "../../assets/image/IMG-Chandiram.jpg";
import ImgKeshav from "../../assets/image/IMG-Keshav.jpg";
import ImgSiddharth from "../../assets/image/IMG-Siddharth.jpg";
import ImgAmit from "../../assets/image/IMG-Amit.jpg";
import ImgShankar from "../../assets/image/IMG-Shankar.jpg";
import ImgDheeraj from "../../assets/image/IMG-Dheeraj.jpg";
import ImgAjay from "../../assets/image/IMG-Ajay.jpg";
import ImgManish from "../../assets/image/IMG-Manish.jpg";
import ImgKrishan from "../../assets/image/IMG-Krishan.jpg";
import ImgSantosh from "../../assets/image/IMG-Santosh.jpg";
import SliderOne from "../../assets/image/group-pic.JPG";
import SliderTwo from "../../assets/image/slider-two.jpg";
import SliderThree from "../../assets/image/slider-three.jpg";
import SliderFour from "../../assets/image/slider-four.jpg";
import SliderFive from "../../assets/image/slider-five.jpg";


const MainPage = () => {
  const [show, setShow] = useState(false);
  const [number, setNumber] = useState("");
  const [visitorName, setVisitorName] = useState("");
  const [photo, setPhoto] = useState();
  const [idProofFront, setIDProofFront] = useState();
  const [idProofBack, setIDProofBack] = useState();
  const [category, setCategory] = useState("visitor");
  const [email, setEmail] = useState("");
  const [city, setCity] = useState("");
  const [address, setAddress] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [visitingCard, setVisitingCard] = useState("");
  const [mobileError, setMobileError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState(false);
  const navigate = useNavigate();
  const [emailError, setEmailError] = useState("");
  const [visitorListData, setVisitorListData] = useState("");
  const [registrationDate, setRegistrationDate] = useState("");
  const [companyshow, setCompanyShow] = useState(false);
  const [agentsshow, setAgentsShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleCompanyClose = () => setCompanyShow(false);
  const handleCompanyShow = () => setCompanyShow(true);

  const handleAgentsClose = () => setAgentsShow(false);
  const handleAgentsShow = () => setAgentsShow(true);

  let currentLocation = useLocation().pathname;

  useEffect(() => {
    if (currentLocation === "/registration") {
      setShow(true);
    }
  }, []);

  // for navigation
  // const nav = () => {
  //   navigate("/visitor-registration");
  // };
  function handlePhoto(e) {
    setPhoto(e.target.files[0]);
  }
  function handleIDFront(e) {
    setIDProofFront(e.target.files[0]);
  }
  function handleIDBack(e) {
    setIDProofBack(e.target.files[0]);
  }

  function handleCard(e) {
    setVisitingCard(e.target.files[0]);
  }

  const handleSubmit = () => {
    if (!visitorName) {
      alert("Name is mandatory");
    } else if (!number) {
      alert("Mobile No. is mandatory");
    } else if (number.length !== 10) {
      alert("Please Enter 10 Digit Mobile Number");
      setIsLoading(false);
      return;
      // } else if (!email) {
      //   alert("Email is mandatory");
      // } else if (emailError) {
      //   alert("Invalid email format");
      //   setIsLoading(false);
      //   return;
    } else if (!companyName) {
      alert("Company Name is mandatory");
    } else if (!city) {
      alert("City is mandatory");
      // } else if (!address) {
      //   alert("Address is mandatory");
      // } else if (!visitingCard) {
      //   alert("Visiting Card is mandatory");
    } else if (!photo) {
      alert("Photo is mandatory");
    } else if (!idProofFront) {
      alert("ID Proof Front is mandatory");
    } else if (!idProofBack) {
      alert("ID Proof Back is mandatory");
    } else {
      setIsLoading(true);
      const formData = new FormData();
      formData.append("mobile_number", number);
      formData.append("visitor_name", visitorName);
      formData.append("photo", photo);
      formData.append("id_proof_front", idProofFront);
      formData.append("id_proof_back", idProofBack);
      formData.append("category", category);
      formData.append("email", email);
      formData.append("city", city);
      formData.append("street_address", address);
      formData.append("visiting_card", visitingCard);
      formData.append("company_name", companyName);
      axios
        .post(
          `${process.env.REACT_APP_API_END_POINT}/visitor-registration`,
          formData
        )
        .then((res) => {
          const value = res.data.message;
          setVisitorListData(value.barcode);
          setRegistrationDate(moment(value.createdAt).format("MMM Do YY"));
          setShow(false);
          setSuccessMessage(true);
          setIsLoading(false);
          setNumber("");
        });
    }
  };

  const handleMobileChange = (e) => {
    const value = e.target.value;
    // Use a regular expression to check if the input is a number
    if (/^\d*$/.test(value)) {
      setNumber(value);
      setMobileError("");
    } else {
      setMobileError("Mobile must contain only numbers.");
    }
  };
  const handleContactPersonChange = (e) => {
    const newValue = e.target.value;
    const alphabetOnlyValue = newValue.replace(/[^A-Za-z ]/g, "");
    setVisitorName(alphabetOnlyValue);
  };
  const handleCity = (e) => {
    const newValue = e.target.value;
    const alphabetOnlyValue = newValue.replace(/[^A-Za-z ]/g, "");
    setCity(alphabetOnlyValue);
  };

  const handleEmailChange = (e) => {
    const value = e.target.value;
    setEmail(value);

    const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;

    if (!emailPattern.test(value)) {
      setEmailError("Invalid email format");
    } else {
      setEmailError("");
    }
  };
  useEffect(() => {
    // Initialize Swiper
    const mySwiper = new Swiper(".swiper-container", {
      // Swiper options and configuration here
      slidesPerView: 1,
      spaceBetween: 10,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
    });
  }, []);

  const [isActive, setIsActive] = useState(false);

  // Function to toggle the class
  const toggleClass = () => {
    setIsActive(!isActive);
  };

  // Define the class name based on the state
  const className = isActive ? "our-story-show" : "our-story-hide";
  // Define the button text based on the state
  const buttonText = isActive ? "Know Less" : "Know More";

  useEffect(() => {
    const wow = new WOW.WOW();
    wow.init();
  }, []);

  // JOHI9, NOIX6, KXNT6, ZOIR4, DZYU4, BSYG9, JJPG4, HAIK2, AHQI1, HUVP9, UKDZ0, OEEU6, KCLP9, CTXE1, 
  const companydata = [
    {
      "name": "AADIRA FASHIONS",
      "person": "AMIT JINDAL",
      "phoneNumber": "99280-66686",
      "caddress": "F254-A1, RIICO INDUSTRIAL AREA, MANSARAVOAR, JAIPUR -302020"
    },
    {
      "name": "AAPNO TEX FAB",
      "person": "CHANDIRAM KHATRI",
      "phoneNumber": "93145-13167",
      "caddress": "102, GROUND FLOOR, G-1-132, RIICO INDUSTRIAL AREA, MANSAROVAR, JAIPUR, RAJASTHAN 302020"
    },
    {
      "name": "ADAA JAIPUR",
      "person": "KESHAV SHUKLA",
      "phoneNumber": "94140-70003",
      "caddress": "H-5, RIICO INDUSTRIAL AREA MANSAROVAR, JAIPUR, RAJASTHAN 302020"
    },
    {
      "name": "ADITYA DESIGNER",
      "person": "UTKARSH WADHAWANI",
      "phoneNumber": "99779-84233",
      "caddress": "H-27, MANSAROVAR INDUSTRIAL AREA, RIICO, JAIPUR-302020 (RAJ.)"
    },
    {
      "name": "ADITI FAB CREATION LLP",
      "person": "AJAY RAI",
      "phoneNumber": "95496-44777",
      "caddress": ""
    },
    {
      "name": "AHUJA ORIENTED",
      "person": "ANCHAL AHUJA",
      "phoneNumber": 9829013002,
      "caddress": "4\/53, JAWAHAR NAGAR, JAIPUR, RAJASTHAN 302004"
    },
    {
      "name": "AMAN EXPORTS INTERNATIONAL",
      "person": "VIKASH AGRWAL",
      "phoneNumber": "98290-67578",
      "caddress": "G-93, SITAPURA INDUSTRIAL AREA, SITAPURA, JAIPUR, RAJASTHAN 302022"
    },
    {
      "name": "AMAN LIFESTYLE",
      "person": "SURESH KHANDELWAL",
      "phoneNumber": "98290-15578",
      "caddress": "A-165, APPAREL PARK, MAHAL ROAD, JAGATPURA, JAIPUR, RAJASTHAN 303905"
    },
    {
      "name": "ANANY EXPO.",
      "person": "MAHESH KUMAR SHARMA",
      "phoneNumber": "93143-20203",
      "caddress": "G1-932, RIICO INDUSTRIAL AREA , SITAPURA, JAIPUR -302022"
    },
    // {
    //   "name": "ARADHANA DESIGNERS",
    //   "person": "NARESH LADHANI \/ MAHENDRA LADHANI",
    //   "phoneNumber": "90575-46072",
    //   "caddress": "PLOT NO. G1-201, 2ND FLOOR, RIICO INDUSTRIAL AREA, MANSAROVAR, JAIPUR, RAJASTHAN 302020"
    // },
    {
      "name": "ASHOK APPARELS",
      "person": "ABHSHEK JAIN",
      "phoneNumber": "97839-51998",
      "caddress": " 4\/173, RICCO INDUSTRIAL AREA, SFS MANSAROVAR, JAIPUR"
    },
    {
      "name": "BABA GARMENTS",
      "person": "PURUSHOTTAM TAILOR",
      "phoneNumber": "93526-84209",
      "caddress": "06, SHAKTI VIHAR, NEAR RIICO INDUSTRIAL AREA, MANSAROVAR, JAIPUR-302020"
    },
    {
      "name": "BDS REALTY",
      "person": "RAHUL JI",
      "phoneNumber": "92681-39656",
      "caddress": ""
    },
    {
      "name": "BEDTIME STORIES LLP",
      "person": "SAHAJ TAMBI",
      "phoneNumber": "86192-32876 \/ 9680935564",
      "caddress": "389, NEMISAGAR, VAISHALI NAGAR, JAIPUR, RAJASTHAN 302021"
    },
    // {
    //   "name": "BHAVYA SALES",
    //   "person": "ANIL SINGH SEKHAWAT",
    //   "phoneNumber": "93513-33335",
    //   "caddress": "G-201, ICICI BNANK LEN NEAR GALAXY CENEMA RIICO IND AREA , JAIPUR-302020"
    // },
    {
      "name": "Benaaz collection",
      "person": "MANISH GOLANI",
      "phoneNumber": "98290-61224",
      "caddress": ""
    },
    {
      "name": "C.S.  EXPORTS",
      "person": "TARIN PODDAR",
      "phoneNumber": "98290-15762",
      "caddress": "E-196 A, RIICO INDUSTRIAL AREA, MANSAROVAR, JAIPUR, RAJASTHAN 302001"
    },
    {
      "name": "CIGNAL CREATION",
      "person": "DEVANSH KATARIA",
      "phoneNumber": "90010-66040",
      "caddress": "H1-80 OPP GALAXY CINEMA RIICO INDUSTRIAL AREA , MANSAROVAR, JAIPUR-302020"
    },
    {
      "name": "CKM FASHIONS (INDIA) PRIVATE LIMITED",
      "person": "ATUL GUPTA",
      "phoneNumber": "98291-31113",
      "caddress": "H-353, EPIP, RIICO INDUSTRIAL AREA, SITAPURA, JAIPUR, RAJASTHAN 302022"
    },
    // {
    //   "name": "COTLER CREATIONS",
    //   "person": "KARTIK JAIN",
    //   "phoneNumber": "90011-53000",
    //   "caddress": "H-85, RIICO INDUSTRIAL AREA, SITAPURA , TONK ROAD, JAIPUR-302022      "
    // },
    {
      "name": "CPS FASHION",
      "person": "CHANDRA PRAKASH",
      "phoneNumber": "76655-44555",
      "caddress": ""
    },
    {
      "name": "DESIGN WORLD",
      "person": "PRAKASH CHAND \/ BUNTY ",
      "phoneNumber": "98290-56554 \/ 98291-43229",
      "caddress": "F-196, EPIP, RIICO INDUSTRIAL AREA, TONK ROAD, SITAPURA, JAIPUR, RAJASTHAN 302022"
    },
    // {
    //   "name": "DEXTEREOUS TRADE CONNECT PVT LTD",
    //   "person": "ANKIT YADAV",
    //   "phoneNumber": "83870-87212",
    //   "caddress": "H-165 MANSAROVAR RIICO, JAIPUR 302020"
    // },
    // {
    //   "name": "DHAKAD FASHION PVT LTD",
    //   "person": "AJAY SINGH DHAKAD \/ VIJAY SINGH DHAKAD",
    //   "phoneNumber": "98290-52726 \/ 94140-53524",
    //   "caddress": "H-685, RIICO INDUSTRIAL AREA, SITAPURA, TONK ROAD, JAIPUR, RAJASTHAN 302022"
    // },
    {
      "name": "DRESSAR FASHIONS",
      "person": "CHHINTU LALWANI",
      "phoneNumber": "98292-66496",
      "caddress": "G-992 , 3RD-FLOOR III-PHASE – NEAR BALAJI MARKET , RIICO INDUSTRICAL AREA , SITAPURA JAIPUR , RAJASTHAN . 302022 "
    },
    {
      "name": "EVERBLOOM RETAIL PVT.LTD.",
      "person": "DEVENDRA SINGH SHEKHAWAT",
      "phoneNumber": 9636965559,
      "caddress": "F202-204, RIICO INDUSTRIAL AREA, MANSAROVAR, JAIPUR-302020"
    },
    {
      "name": "EXPORT WAREHOUSE",
      "person": "GAURAV DUGAR",
      "phoneNumber": "98290-81819",
      "caddress": ""
    },
    // {
    //   "name": "FAB STYLE",
    //   "person": "ANKUR SACHDEVA",
    //   "phoneNumber": "97992-63999",
    //   "caddress": "G1-335 RICCO INDUSTRIAL AREA BEHIND AMOL PHARMACEUTICALS SITAPURA JAIPUR -302022"
    // },
    {
      "name": "FAB STORY",
      "person": "MANISH KHATRI",
      "phoneNumber": "93520-77199",
      "caddress": ""
    },
    {
      "name": "FASHION DWAR PVT.LTD",
      "person": "DEEPAK JAIN",
      "phoneNumber": "98290-10732",
      "caddress": "H-1\/ 103A, RIICO INDUSTRIAL AREA, MANSAROVAR, JAIPUR, RAJASTHAN 302020"
    },
    {
      "name": "FLAMBOYANT",
      "person": "VINEET SOMANI \/ SUMIT MAHESHWARI",
      "phoneNumber": "98281-03293 \/ 98292-55951",
      "caddress": "G-1-161, EPIP, GARMENT ZONE, RIICO INDUSTRIAL AREA, SITAPURA, JAIPUR - 302022"
    },
    {
      "name": "FOCUS APPAREL",
      "person": "NARESH GOLANI \/ VIPIN BHAMBHANI",
      "phoneNumber": "98292-56046 \/ 93517-80350",
      "caddress": " H-14-15, RIICO INDUSTRIAL AREA, MANSAROVAR, JAIPUR, RAJASTHAN 302029"
    },
    // {
    //   "name": "GOPALAS FAB PRINTS",
    //   "person": "MANISH RATHI",
    //   "phoneNumber": "94140-31070",
    //   "caddress": " G- 1 \/269, EPIP, SITAPURA INDUSTRIAL AREA, JAIPUR, RAJASTHAN 302022"
    // },
    {
      "name": "GOODWIL IMPEX",
      "person": "ABHIMANYU",
      "phoneNumber": "99873-44855",
      "caddress": ""
    },
    {
      "name": "GOURIWALA TEXTILES PRIVATE LIMITED",
      "person": "VIJAY KUMAR KHATRI",
      "phoneNumber": "92612-36614",
      "caddress": "G1-152, EPIP GARMENT ZONE, SITAPURA INDUSTRIAL AREA, TONK ROAD, JAIPUR -302022 "
    },
    {
      "name": "GOYAL GUMS PVT.LTD.",
      "person": "KAMAL RAGHANI",
      "phoneNumber": "83870-55000",
      "caddress": ""
    },
    {
      "name": "GRAND LIFESTYLE (FABCULTURE)",
      "person": "ANKIT MAMODIA",
      "phoneNumber": "98290-44363",
      "caddress": ""
    },
    // {
    //   "name": "H.K. FASHIONS",
    //   "person": "NAVJOT SINGH",
    //   "phoneNumber": 9887764040,
    //   "caddress": "A-79, SHIVPURI COLONY , NEAR CHILGADI RESTRONT, AIRPORT KE PASS SANGANER, JAIPUR 302029"
    // },
    // {
    //   "name": "H.S FASHION",
    //   "person": "RAMANDEEP SINGH",
    //   "phoneNumber": "96027-03434",
    //   "caddress": "E-704 OPP.NISHAN SERVICE CENTER CHATRALA CIRCLE , RIICO INDUSTRIAL AREA JAIPUR "
    // },
    // {
    //   "name": "HERITAGE JAIPUR",
    //   "person": "RAVI HASSANI",
    //   "phoneNumber": "98293-21212",
    //   "caddress": "PLOT NO 3, NEAR CHORDIA PETROL PUMP, ABOVE RELIANCE FRESH SANGANER, JAIPUR 302029"
    // },
    {
      "name": "IN DESIGNE",
      "person": "AMAN KHANNA",
      "phoneNumber": "99999-98832",
      "caddress": ""
    },
    // {
    //   "name": "J AND S FASHION ",
    //   "person": "RINKU JAIN \/ KANHA SHARMA ",
    //   "phoneNumber": "97992-07070 \/ 9887038417",
    //   "caddress": "NEAR ASHIRWAD LAWN GOVINDPURA ROAD, BEHIND SHIV MANDIR, GOVARDHAN NAGAR TOLLTAX, JAIPUR-302033"
    // },
    // {
    //   "name": "JAYPORE FASHIONS",
    //   "person": "RAVI KUMAR MOOLCHANDANI",
    //   "phoneNumber": "98288-31161",
    //   "caddress": "H1-237, RIICO INDUSTRIAL AREA, MANSAROVAR, JAIPUR, RAJASTHAN 302020"
    // },
    // {
    //   "name": "JKJS SALES",
    //   "person": "ABHISHEK",
    //   "phoneNumber": "98106-48243",
    //   "caddress": "H-277, RICCO INDUSTRIAL AREA, EPIP. SITAPURA, JAIPUR, RAJASTHAN - 302022"
    // },
    // {
    //   "name": "KAAMINI FASHION",
    //   "person": "VIKAS KUMAR KANDOI",
    //   "phoneNumber": "96729-98955",
    //   "caddress": "F-136, IT PARK ROAD, CHATRALA CIRCLE, RIICO SITAPURA"
    // },
    // {
    //   "name": "KALINDI FASHION & CRAFT",
    //   "person": "PIUSH DHADDA",
    //   "phoneNumber": 9829016262,
    //   "caddress": "SP-14, MANSAROVAR, RIICO INDUSTRIAL AREA, JAIPUR-302020"
    // },
    // {
    //   "name": "KAMAL FASHION",
    //   "person": "GAURI SHANKER KHATRI",
    //   "phoneNumber": "98281-17074",
    //   "caddress": "H-141, RIICO INDUSTRIAL AREA, MANSAROVAR, JAIPUR - 302020"
    // },
    // {
    //   "name": "KAMYA FASHIONS",
    //   "person": "MANISH JAIN \/ PRATHAM JAIN",
    //   "phoneNumber": "98280-18956 \/ 7230993222",
    //   "caddress": "H-179, EPIP RIICO INDUSTRIAL AREA, SITAPURA, JAIPUR - 302022"
    // },
    // {
    //   "name": "KAPAAS",
    //   "person": "HARKISHAN ",
    //   "phoneNumber": "86968-69686",
    //   "caddress": "1ST FLOOR, PLOT NO. 102, JHALANIYON KA RASTA, JAIPUR-302004"
    // },
    {
      "name": "KHAVAWALA TEXTILES PVT.LTD.",
      "person": "PIYUSH SINGHAL",
      "phoneNumber": "96435-73000",
      "caddress": ""
    },
    {
      "name": "KIRAN MODES",
      "person": "KAPIL GUPTA",
      "phoneNumber": "98292-12539",
      "caddress": "G -1 -269 G-1-270, RIICO INDUSTRIAL AREA, SITAPURA, JAIPUR-302022"
    },
    // {
    //   "name": "KUMAR LIFESTYLE",
    //   "person": "KISHAN KUMAR TOTLA",
    //   "phoneNumber": "96499-90062",
    //   "caddress": "105, JEM VIHAR, BEHIND NAGAR NIGAM STADIUM, SANGANER JAIPUR-302020"
    // },
    // {
    //   "name": "LAXMI APPARELS",
    //   "person": "HIMANSU \/ LALIT",
    //   "phoneNumber": "99290-88831 \/ 99501-49879",
    //   "caddress": "H-139 RIICO INDUSTRIAL AREA, MANSAROVAR, JAIPUR 302020"
    // },
    {
      "name": "LIVE SATY FASHIONS PVT.LTD (CS EXPORTS)",
      "person": "ARNAV PODDAR",
      "phoneNumber": "99293-77000",
      "caddress": ""
    },
    // {
    //   "name": "M M FASHION",
    //   "person": "SANJAY MANGAL",
    //   "phoneNumber": "99296-18675",
    //   "caddress": "87, MOZI COLONY , MALVIYA NAGAR , JAIPUR"
    // },
    {
      "name": "M.K. SILK AND SAREES",
      "person": "KISHAN BHAGCHANDANI",
      "phoneNumber": "98291-83379",
      "caddress": ""
    },
    // {
    //   "name": "M. R. CREATIONS ",
    //   "person": "RAKESH CHAUHAN",
    //   "phoneNumber": "93771-51034",
    //   "caddress": "SHOP NO: 21-23, GROUND FLOOR , SHASTRI MARKET 20-DUKAN , ADARSH NAGAR, JAIPUR-302022"
    // },
    // {
    //   "name": "M.K. SILK AND SAREES ",
    //   "person": "KISHAN CHAND BHAGCHANDANI ",
    //   "phoneNumber": 9829183379,
    //   "caddress": "G1-129, MANSAROVAR INDUSTIRAL AREA, JAIPUR-302020"
    // },
    {
      "name": "M.K. TAILORING HOUSE (RAMA'S)",
      "person": "RAMAWATAR TAILOR",
      "phoneNumber": "93140-78703",
      "caddress": "G1-91, SITAPURA INDUSTRIAL AREA, TONK ROAD, JAIPUR"
    },
    {
      "name": "M\/S  LASHKERY STORE",
      "person": "MANOJ LASHKERY",
      "phoneNumber": "93145-28321",
      "caddress": "H12 RICCO INDUSTRIAL AREA MANSAROVER JAIPUR 302020"
    },
    {
      "name": "MA'AM ARTS AADEWS",
      "person": "RAJEEV DEEWAN",
      "phoneNumber": "98292-52052",
      "caddress": "G1-51, SITAPURA INDUSTRIAL AREA, JAIPUR, RAJASTHAN 302022"
    },
    {
      "name": "MA'AM ARTS MISRI",
      "person": "RAJEEV DEEWAN",
      "phoneNumber": "98292-52052",
      "caddress": ""
    },
    {
      "name": "MAHALAXMI TEX FAB",
      "person": "SUBHAS AGARWAL",
      "phoneNumber": "98290-56846",
      "caddress": "H - 18, RIICO INDUSTRIAL AREA, MANSAROVAR, JAIPUR - 302020"
    },
    {
      "name": "MAHARANI FABRICS LLP",
      "person": "ABHISHEK KHUNTETA",
      "phoneNumber": "94141-53678",
      "caddress": "F-287, SITAPURA INDUSTRIAL AREA, SANGANER, JAIPUR, RAJASTHAN 302022"
    },
    {
      "name": "NARANG TEXTILE",
      "person": "PRINCE NARANG",
      "phoneNumber": "96724-57777",
      "caddress": ""
    },
    // {
    //   "name": "MEENA'S FASHION",
    //   "person": "VIKASH MEENA",
    //   "phoneNumber": "82096-96155",
    //   "caddress": "G 551, SITAPURA INDUSTRIAL AREA, SITAPURA, JAIPUR, RAJASTHAN 302022"
    // },
    // {
    //   "name": "PAWAN CREATIONS PVT.LTD.",
    //   "person": "DURGESH LASHKERY",
    //   "phoneNumber": "95717-14999",
    //   "caddress": "E-187, INDUSTRIAL AREA , APPAREL PARK , JAIPUR-302022"
    // },
    // {
    //   "name": "PRABHASONS",
    //   "person": "YUVRAJ AGARWAL \/ ADHIRAJ AGARWAL",
    //   "phoneNumber": "98297-06042 \/ 96604-46646",
    //   "caddress": "G 696 D, SITAPURA INDUSTRIAL AREA, SANGANER, JAIPUR , RAJASTHAN 302022"
    // },
    {
      "name": "RAHUL PRINTS",
      "person": "RAHUL KHATRI",
      "phoneNumber": "96496-22333",
      "caddress": "PLOT NO. 3 & 4, KEWAL COMPOUND, NAMDEV COLONY, LANE NO. 01, SANGANER, JAIPUR- 302029(RAJ.)"
    },
    // {
    //   "name": "RAYWOMEN CLOTHING PVT.LTD.",
    //   "person": "CHANDRA PRAKASH CHANDAK",
    //   "phoneNumber": "98870-37000",
    //   "caddress": "G1-542 , 1ST FLOOR, SITAPURA INDUSTRIAL AREA , JAIPUR-302022"
    // },
    // {
    //   "name": "REGARDS FASHION",
    //   "person": "DHEERAJ BAPLAWAT",
    //   "phoneNumber": "97845-42474",
    //   "caddress": "F-121 APPAREL, PARK RIICO INDUSTRIAL AREA, JAGATPURA, JAIPUR, RAJASTHAN 302017"
    // },
    // {
    //   "name": "ROOP NANDINI INDUSTRIES",
    //   "person": "PURANJAY AGARWAL",
    //   "phoneNumber": "89559-96936 \/ 94133-45308",
    //   "caddress": "H-149 A, MANSAROVAR, JAIPUR, RAJASTHAN 302020"
    // },
    {
      "name": "RATNAWALI FABTEX PVT.LTD.",
      "person": "YASH TATIWALA",
      "phoneNumber": "95304-09987",
      "caddress": ""
    },
    {
      "name": "READIPRINT FASHIONS",
      "person": "RAHUL SABLAWAT",
      "phoneNumber": "98281-11103",
      "caddress": ""
    },
    {
      "name": "REGARDS FASHION",
      "person": "DHEERAJ BAPLAWAT",
      "phoneNumber": "97845-42474",
      "caddress": ""
    },
    {
      "name": "ROOP SANGAM",
      "person": "ASHOK KUMAR GUPTA",
      "phoneNumber": "98291-83446",
      "caddress": "BASMENT, E-258 A, RIICO INDUSTRIAL AREA, MANSAROVAR, JAIPUR, RAJASTHAN 302020"
    },
    // {
    //   "name": "ROYAL CREATIONS",
    //   "person": "HITESH GOYAL",
    //   "phoneNumber": "97297-91432",
    //   "caddress": "SP-1013 B, SITAPURA INDUSTRIAL AREA, JAIPUR, RAJASTHAN 302022"
    // },
    {
      "name": "S.S. FASHION",
      "person": "YOGESH ",
      "phoneNumber": "85618-06121",
      "caddress": ""
    },
    // {
    //   "name": "S.K. FASHION",
    //   "person": "KAPIL ",
    //   "phoneNumber": "99964-53092",
    //   "caddress": "F 672 RICCO SITAPURA INSDUSRAIL AREA, JAIPUR"
    // },
    // {
    //   "name": "SALONA BICHONA FABTEX INDIA PVT. LTD.",
    //   "person": "RAJESH JAIN",
    //   "phoneNumber": "94140-76387",
    //   "caddress": "G-1, PHASE 3, 1282, SITAPURA INDUSTRIAL AREA, JAIPUR, RAJASTHAN 302022"
    // },
    {
      "name": "SARC ORIGINAL ",
      "person": "SUMIT KABRA ",
      "phoneNumber": "98290-50219",
      "caddress": "F- 133, RIICO INDUSTRIAL AREA, SITAPURA, JAIPUR, RAJASTHAN 302022"
    },
    // {
    //   "name": "SARK INDIA",
    //   "person": "SUMIT MODI",
    //   "phoneNumber": "94140-47346",
    //   "caddress": "C-24-B, MALVIYA INDUSTRIAL AREA, JAIPUR, RAJASTHAN-302017"
    // },
    {
      "name": "SAVI FASHION APPARELS PVT.LTD.",
      "person": "AARTI PAREEK",
      "phoneNumber": "70144-62678",
      "caddress": "H-109, 110, 111, RIICO INDUSTRIAL AREA, MALVIYA NAGAR, JAIPUR, RAJASTHAN 302017"
    },
    {
      "name": "SHILA DESIGNER SAREES",
      "person": "NARESH",
      "phoneNumber": "80034-84304",
      "caddress": "P. NO. 19, CHITRAKOOT COLONY-A, BEHIND REASON HOSPITAL, SANGANER, JAIPUR"
    },
    // {
    //   "name": "SHIVANI CREATION PVT.LTD",
    //   "person": "RAJESH KUMAR BHAKHAR",
    //   "phoneNumber": "98298-70003",
    //   "caddress": "G1-181, RIICO INDUSTRIAL AREA, MANSAROVAR, JAIPUR-302020"
    // },
    {
      "name": "SHREE AMBIKA FASHION",
      "person": "MANISH KUMAR GARG",
      "phoneNumber": "96105-22155",
      "caddress": "G-110, TONK ROAD, SITAPURA INDUSTRIAL AREA, JAIPUR, RAJASTHAN 302022"
    },
    // {
    //   "name": "SHREE ANUSHKA FASHION  LLP",
    //   "person": "ALKA LADIWAL \/ PARAG LADIWAL ",
    //   "phoneNumber": "94618-84200 \/ 98290-44544",
    //   "caddress": "PLOT. NO:- G1 -78, RIICO INDUSTRIAL AREA, APPAREL PARK, MAHAL ROAD, JAGATPURA, JAIPUR-302017"
    // },
    // {
    //   "name": "SHREE BALAJI FASHION",
    //   "person": "UMESH GARG",
    //   "phoneNumber": "70624-93100",
    //   "caddress": "E-95, GARMENT ZONE , EPIP , SITAPURA INDUSTRIAL AREA, JAIPUR-302022"
    // },
    // {
    //   "name": "SHREE GANESHAM",
    //   "person": "ASHOK JINDAL",
    //   "phoneNumber": "98318-89561",
    //   "caddress": "G1\/35-36, BACK SIDE OF BHARAT PETROL PUMP, RIICO SITAPURA INDUSTRIAL AREA, JAIPUR-302022"
    // },
    {
      "name": "SHREE RUKMANI FABTEX PVT.LTD.",
      "person": "BHARAT JI",
      "phoneNumber": "90013-35222",
      "caddress": "G1\/123-125 EPIP RICCO INDUSTRIAL AREA, SITAPURA, JAIPUR-22 (RAJ.), FACTORY II: B-166, RIICO APPAREL PARK, MAHAL ROAD JAGATPURA, JAIPUR-17 "
    },
    {
      "name": "SHREE SALASAR DORI",
      "person": "SHANKAR JOSHI ",
      "phoneNumber": "96362-22000",
      "caddress": "E-191 A, RIICO INDUSTRIAL AREA, MANSAROVAR, JAIPUR-302020"
    },
    // {
    //   "name": "SHRI LAXMI FASHION",
    //   "person": "DEEPAK SEWANI \/ JAI SEWANI",
    //   "phoneNumber": "82903-02874",
    //   "caddress": "H-226, RIICO INDUSTRIAL AREA, MANSAROVAR, JAIPUR-302020"
    // },
    // {
    //   "name": "SHUBHAM CREATION",
    //   "person": "SUNIL SRIVASTAV",
    //   "phoneNumber": "76654-31255",
    //   "caddress": "G1-41 SITAPURA INDUSTRIAL AREA JAIPUR-302022"
    // },
    {
      "name": "SIGNORIA CREATION LIMITED",
      "person": "KRITIKA AGARWAL",
      "phoneNumber": "96605-90061",
      "caddress": "324, AAYKER NAGAR 2ND, GRAM KLYNPURA, SANGANER, JAIPUR, RAJASTHAN 302020"
    },
    // {
    //   "name": "SILAI BUNAI",
    //   "person": "PUNIT JAIN  \/ SIDDHARTH JI",
    //   "phoneNumber": "98295-93098 \/ 98291-25666",
    //   "caddress": "G-1-129, RIICO, MANSAROVAR, JAIPUR, RAJASTHAN 302020"
    // },
    // {
    //   "name": "STYLE HUB",
    //   "person": "VINOD SHARDA",
    //   "phoneNumber": 9929655504,
    //   "caddress": "C-2, RIICO MANSAROVAR INDUSTRIAL AREA, JAIPUR-302020"
    // },
    {
      "name": "SUTRAM",
      "person": "SATISH AGARWAL",
      "phoneNumber": "98290-14140",
      "caddress": "27\/D SARAF TOWER, SARASWATI MARG NEAR GOKUL NIWAS MI ROAD JAIPUR"
    },
    {
      "name": "URBAN BASICS",
      "person": "SIDHARTH JAIN",
      "phoneNumber": "98290-07557",
      "caddress": "BASEMENT AND GROUND FLOOR, H-16, MANSAROVAR INDUSTRIAL AREA, MANSAROVAR, JAIPUR, RAJASTHAN 302020"
    },
    {
      "name": "VASTRAMAYAA CREATION",
      "person": "AYUSH SINGHAL",
      "phoneNumber": "72788-88887",
      "caddress": "H 1 -48, MANSAROVAR, RIICO INDUSTRIAL AREA, JAIPUR, RAJASTHAN 302020"
    },
    // {
    //   "name": "VAVA DESIGNS",
    //   "person": "VAIBHAV GUPTA \/ PRAKASH GUPTA",
    //   "phoneNumber": "97993-44878 \/ 98296-82209",
    //   "caddress": "47, SHAKTI VIHAR VISTAR, RAMPURA ROAD, SANGANER , JAIPUR-302021"
    // },
    // {
    //   "name": "VIKAS ENTERPRISES",
    //   "person": "VIKAS GUPTA",
    //   "phoneNumber": "98280-22181",
    //   "caddress": "H-159, RIICO MANSAROVAR, JAIPUR -302020  "
    // },
    {
      "name": "YUFTA LLP",
      "person": "RAVI KHANDELWAL",
      "phoneNumber": "94144-15002",
      "caddress": "H 1-99E, RIICO INDUSTRIAL AREA, MANSAROVAR, JAIPUR, RAJASTHAN 302020"
    },
    {
      "name": "ZYOD COMMERCE PRIVATE LIMITED",
      "person": "RITESH KUMAR KHANDELWAL",
      "phoneNumber": "70739-93222",
      "caddress": "E-258-B, INDUSTRIAL AREA MANSAROVAR, JAIPUR-302020"
    }
  ];

  const agentdata = [
    {
      "name": "ADITYA GROUP",
      "person": "ASHOK KACHOLIYA",
      "phoneNumber": "97728-00777"
    },
    {
      "name": "AKSHAY AGENCY",
      "person": "SURAJ BHAN BANSAL",
      "phoneNumber": "90011-68888"
    },
    // {
    //   "name": "ASHOKA AGENCY",
    //   "person": "BHANWAR SINGH",
    //   "phoneNumber": "94142-69544"
    // },
    {
      "name": "DEEPAK AGENCIES",
      "person": "DEEPAK GUPTA",
      "phoneNumber": "98291-23724"
    },
    {
      "name": "GH TEXTILE AND SAREE AGENCY",
      "person": "BABU LAL PUROHIT",
      "phoneNumber": "88822-90099"
    },
    // {
    //   "name": "ISHAANI MARKETING",
    //   "person": "ANUBHAV SINGHANIA",
    //   "phoneNumber": 9322665911
    // },
    // {
    //   "name": "JAI SHRI AGENCIES",
    //   "person": "PRAKASH SINGH RAJPUROHIT",
    //   "phoneNumber": "87698-44844"
    // },
    {
      "name": "JAIPUR GH AGENCY",
      "person": "MOHIT BAFNA",
      "phoneNumber": "97999-51914"
    },
    {
      "name": "JAWAHAR AGENCY",
      "person": "OM PRAKASH GANDHI",
      "phoneNumber": "94144-55260"
    },
    {
      "name": "JAIPUR GARMENT CLUB",
      "person": "ASHOK SIR",
      "phoneNumber": "98291-83446"
    },
    {
      "name": "JYOTI AGENCY (A UNIT OF K M AGENCY)",
      "person": "VISHAL NANDWANI",
      "phoneNumber": "99289-96888"
    },
    {
      "name": "KRISHNA TEXTILE",
      "person": "DILIP WADHWANI",
      "phoneNumber": "86193-21669"
    },
    // {
    //   "name": "JM JAIN LLP",
    //   "person": "SUBHASH SANCHETI ",
    //   "phoneNumber": "98737-15277\/92890-98538"
    // },
    // {
    //   "name": "LK SALES JAIPUR",
    //   "person": "NIKHIL RAJPUROHIT",
    //   "phoneNumber": "72177-48913"
    // },
    {
      "name": "MAHESH TEXTILE AGENCY",
      "person": "N MAHESH BABU",
      "phoneNumber": "70885-99999"
    },
    {
      "name": "M M AGENCY ",
      "person": "ABHISHEK MALPANI",
      "phoneNumber": "97990-62000"
    },
    // {
    //   "name": "MAHESH TEXTILE AGENCY",
    //   "person": "N.MAHESH BABU",
    //   "phoneNumber": 8562043197
    // },
    {
      "name": "MOHI MOKSHI AGENCY ",
      "person": "MANISH KUMAR  ",
      "phoneNumber": "95821-67117"
    },
    {
      "name": "MUKESH TEXTILE AGENCY",
      "person": "MUKESH KUMAR SHARMA",
      "phoneNumber": "95716-66808"
    },
    {
      "name": "OM SAI AGENCY ",
      "person": "ANIL AHUJA",
      "phoneNumber": "93148-87024"
    },
    // {
    //   "name": "PAMBA TEXTILE AGENCIES PVT.LTD.",
    //   "person": "V.R.PAMBAVASAN",
    //   "phoneNumber": "93810-31999\/93525-31999"
    // },
    {
      "name": "RAGHANI TRADELINK TEX SERVICES",
      "person": "RAJESH KUMAR",
      "phoneNumber": "98280-30128"
    },
    {
      "name": "RAJGURU AGENCIES",
      "person": "PRAVEEN RAJPROHIT",
      "phoneNumber": "91668-56234"
    },
    {
      "name": "RK AGENCY",
      "person": "SUNNY CHANGLANI",
      "phoneNumber": "96385-38189"
    },
    // {
    //   "name": "SARAOGI SUPER SALES PVT.LTD.",
    //   "person": "RITESH JAIN",
    //   "phoneNumber": "72900-87625"
    // },
    {
      "name": "SHEETAL TEXTILE AGENCY",
      "person": "PAWAN KUMAR",
      "phoneNumber": "98996-60879"
    },
    {
      "name": "SHREE KRISHNA TEXTILE AGENCY",
      "person": "SUSHIL DOKARIYA ",
      "phoneNumber": "93515-98311"
    },
    // {
    //   "name": "SHRI HARI PRIYA AGENCY",
    //   "person": "SUNIL AGARWAL",
    //   "phoneNumber": 940500875
    // },
    {
      "name": "SIDDHARTH TREDELINK",
      "person": "VINOD HEDA",
      "phoneNumber": "81070-74813"
    },
    {
      "name": "SRI RADHAKRISHNA AGENCIES",
      "person": "SATISH BORA",
      "phoneNumber": "93522-24680"
    },
    // {
    //   "name": "THE MERCHANTS",
    //   "person": "KAPIL JAIN",
    //   "phoneNumber": 9845008328
    // },
    // {
    //   "name": "TIRUPATI TEXTILE AGENCY",
    //   "person": "ANIL TODWAL",
    //   "phoneNumber": "93519-38007"
    // },
    {
      "name": "TULSYAN BIZ SUPPORT LLP",
      "person": "RAMGOPAL TULSYAN ",
      "phoneNumber": "83060-40210"
    },
    {
      "name": "YOGESH KUMAR DINESH KUMAR",
      "person": "SARTHAK ARORA",
      "phoneNumber": "77280-88199"
    },
    // {
    //   "name": "SHREE GARMENTS",
    //   "person": "SHIV MUNDHRA",
    //   "phoneNumber": 9828223046
    // },
    // {
    //   "name": "K.BHERUMAL TEXTILE AGENCY",
    //   "person": "ASHOK CHHATLANI",
    //   "phoneNumber": 9990944433
    // },
    // {
    //   "name": "G MALPANI AGENCY LLP",
    //   "person": "GAURAV MALPANI",
    //   "phoneNumber": 9314076186
    // },
    // {
    //   "name": "RK & COMPANY",
    //   "person": "VIKRAM KHANDELWAL",
    //   "phoneNumber": 9929232323
    // }

    // Add more data as needed
  ];

  return (
    <>
      <section className="bg-navbar">
        <div className="navbar navbar-expand-lg p-0">
          <div className="container">
            <a className="navbar-brand" href="#">
              <img src={MainLogo} />
            </a>

            <div className="d-flex align-items-center gap-10">
              <div className="header-text">
                <h5>10 TO 11 JANUARY 2025</h5>
                <h6>CHANDAN VAN, JAIPUR</h6>
              </div>

              <Button
                className="regis"
                type="button"
                data-bs-toggle="modal"
                data-bs-target="#staticBackdrop"
                onClick={() => navigate("/exhibitor/login")}
                style={{ marginRight: '10px' }}
              >
                Exhibitor Login
              </Button>

              <Button
                className="regis"
                type="button"
                data-bs-toggle="modal"
                data-bs-target="#staticBackdrop"
                onClick={() => navigate("/visitor-registration")}
              >
                Visitor Registration
              </Button>
            </div>
          </div>
        </div>
      </section>
      {/* <div className="slider-bg">
        <div className="slider-color-bg">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="slider-text">
                  <h3 className="text-white">
                    Jaipur Garments Club is more than a committee
                  </h3>
                  <h1>Welcome to Jaipur Garments Club</h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}

      {/* new slider component start  */}
      <Carousel indicators={false} interval={5000}>
        <Carousel.Item>
          <div className="slider-bg one">
            <img src={SliderOne} className="slider-img-tag" />
            <div className="slider-color-bg">
              <div className="container">
                <div className="row">
                  <div className="col-12">
                    <div className="slider-text">
                      <h3 className="text-white">
                        Jaipur Garments Club is more than a committee
                      </h3>
                      <h1>Welcome to Jaipur Garments Club</h1>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Carousel.Item>
        <Carousel.Item>
          <div className="slider-bg two">
            <img src={SliderTwo} className="slider-img-tag" />
            <div className="slider-color-bg">
              <div className="container">
                <div className="row">
                  <div className="col-12">
                    <div className="slider-text">
                      <h3 className="text-white">
                        Jaipur Garments Club is more than a committee
                      </h3>
                      <h1>Welcome to Jaipur Garments Club</h1>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Carousel.Item>
        <Carousel.Item>
          <div className="slider-bg three">
            <img src={SliderThree} className="slider-img-tag" />
            <div className="slider-color-bg">
              <div className="container">
                <div className="row">
                  <div className="col-12">
                    <div className="slider-text">
                      <h3 className="text-white">
                        Jaipur Garments Club is more than a committee
                      </h3>
                      <h1>Welcome to Jaipur Garments Club</h1>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Carousel.Item>
        <Carousel.Item>
          <div className="slider-bg four">
            <img src={SliderFour} className="slider-img-tag" />
            <div className="slider-color-bg">
              <div className="container">
                <div className="row">
                  <div className="col-12">
                    <div className="slider-text">
                      <h3 className="text-white">
                        Jaipur Garments Club is more than a committee
                      </h3>
                      <h1>Welcome to Jaipur Garments Club</h1>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Carousel.Item>
        <Carousel.Item>
          <div className="slider-bg five">
            <img src={SliderFive} className="slider-img-tag" />
            <div className="slider-color-bg">
              <div className="container">
                <div className="row">
                  <div className="col-12">
                    <div className="slider-text">
                      <h3 className="text-white">
                        Jaipur Garments Club is more than a committee
                      </h3>
                      <h1>Welcome to Jaipur Garments Club</h1>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Carousel.Item>
      </Carousel>

      {/* new slider component end  */}


      {/* <!-- new section start --> */}
      {/* <section>
        <div className="container">
          <div className="row slider-row ">
            <div className="col-lg-6 col-md-6">
              <div className="president-pic">
                <img src={DeepakImg} />
              </div>
            </div>
            <div className="col-lg-6 col-md-6">
              <div className="president-text ">
                <h3 className="">Mr. Deepak Jain</h3>
                <p>
                  The Esteemed President of Jaipur Garments Club, steers a
                  committee comprising top manufacturers and fashion brands,
                  fostering collaboration and innovation in the dynamic realm of
                  fashion. Under his visionary leadership, the club orchestrates
                  captivating fashion expos, showcasing the industry's
                  cutting-edge trends and creations.
                </p>
              </div>
            </div>
          </div>
          <div className="row slider-row">
            <div className="col-lg-6 col-md-6">
              <div className="president-pic ">
                <img src={AshokImg} />
              </div>
            </div>
            <div className="col-lg-6 col-md-6">
              <div className="president-text py-4">
                <h3>Mr. Ashok Gupta</h3>
                <p>
                  The Dedicated General Secretary of Jaipur Garments Club, plays
                  a pivotal role in coordinating the committee's efforts among
                  top manufacturers and fashion brands, ensuring seamless
                  execution of their acclaimed fashion expos. At JGC, we believe
                  in preserving the traditional artistry that makes Indian
                  textiles unique. Our members are pioneers in the industry,
                  each contributing a distinct perspective that adds depth to
                  our collective vision. We stand for excellence, integrity, and
                  a forward-thinking approach to fashion.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      {/* <!-- new section end --> */}

      {/* <!-- new section start --> */}

      {/* <!-- new section end --> */}
      <section className="section-padding members-section">
        <div className="container">
          <div className="members-heading">
            <h2 className="mb-0">Committee Members</h2>
          </div>
          <div className="row" style={{ gap: '20px 0px' }}>
            <div className="col-lg-4 col-md-6">
              <div className="members-tabs margin">
                <img src={ImgDeepak} />
                <h3>Mr. Deepak Jain</h3>
                <h5>President</h5>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="members-tabs">
                <img src={ImgChandiram} />
                <h3>Mr. Chandiram</h3>
                <h5>Patron</h5>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="members-tabs margin">
                <img src={ImgAshok} />
                <h3>Mr. Ashok Gupta</h3>
                <h5>General Secretary</h5>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="members-tabs">
                <img src={ImgKeshav} />
                <h3>Mr. Keshav Shukla</h3>
                <h5>Vice President</h5>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="members-tabs">
                <img src={ImgSiddharth} />
                <h3>Mr. Siddharth Jain</h3>
                <h5>Joint Secretary</h5>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="members-tabs">
                <img src={ImgAmit} />
                <h3>Mr. Amit Jindal</h3>
                <h5>Treasurer</h5>
              </div>
            </div>
          </div>
          <div className="members-heading mt-4">
            <h2 className="text-center mb-0">Executive Members</h2>
          </div>
          <div className="row justify-content-center" style={{ gap: '20px 0px' }}>
            <div className="col-lg-2 col-md-6 slideInRight">
              <div className="executive-members-tab">
                <img src={ImgShankar} />
                <h6 className="mb-0">
                  Mr. Shankar
                  <br />
                  Joshi
                </h6>
              </div>
            </div>
            <div className="col-lg-2 col-md-6">
              <div className="executive-members-tab">
                <img src={ImgDheeraj} style={{ objectPosition: 'top', height: '150px' }} />
                <h6 className="mb-0">Mr. Dheeraj <br /> Baplawat </h6>
              </div>
            </div>
            <div className="col-lg-2 col-md-6">
              <div className="executive-members-tab">
                <img src={ImgAjay} />
                <h6 className="mb-0">Mr. Ajay Singh <br /> Dhakad </h6>
              </div>
            </div>
            <div className="col-lg-2 col-md-6">
              <div className="executive-members-tab">
                <img src={ImgManish} style={{ objectPosition: 'top', height: '150px' }} />
                <h6 className="mb-0">Mr. Manish Kumar <br /> Garg </h6>
              </div>
            </div>
            <div className="col-lg-2 col-md-6">
              <div className="executive-members-tab">
                <img src={ImgKrishan} style={{ objectPosition: 'top', height: '150px' }} />
                <h6 className="mb-0">Mr. Krishan Chand <br /> Bhagchandani </h6>
              </div>
            </div>
            {/* <div className="col-lg-2 col-md-6">
              <div className="executive-members-tab">
                <img src={ImgSantosh} />
                <h3 className="">Mr. Santosh Deshwal </h3>
                <h5 className="mb-0">Manager JGC</h5>
              </div>
            </div> */}
          </div>
        </div>
        <section className="manager-section">
          <div className="golden-bg">

          </div>
          <div className="container">
            <div className="row mt-4 justify-content-center position-relative">
              <div className="col-lg-2 col-md-6"></div>
              <div className="col-lg-2 col-md-6"></div>
              <div className="col-lg-2 col-md-6"></div>
              <div className="col-lg-2 col-md-6"></div>
              <div className="col-lg-2 col-md-6">
                <div className="executive-members-tab">
                  <img src={ImgSantosh} style={{ objectPosition: 'top', height: '150px' }} />
                  <h6 className="">Mr. Santosh Deshwal </h6>
                  <span style={{ fontSize: '12px' }} className="mb-0">Manager JGC</span>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className="container">
          <div className="row mt-4 regis-btns" style={{ gap: '16px 0px' }}>
            <div className="col-lg-6 col-md-6 text-center">
              <Button className="regis" onClick={handleCompanyShow}>
                Registered Manufacturers
              </Button>

              {/* <div className="executive-members-tab">
                <img src={ImgSantosh} />
                <h6 className="">Mr. Santosh Deshwal </h6>
                <span style={{ fontSize: '12px' }} className="mb-0">Manager JGC</span>
              </div> */}
            </div>
            <div className="col-lg-6 col-md-6 text-center">
              <Button className="regis" onClick={handleAgentsShow}>
                Registered Agents
              </Button>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- new section start --> */}

      {/*
      <div className="bg-grey mt-5">
        <section className="section-padding" id="events">
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <div className="about-img">
                  <img src={AboutImg} style={{ maxWidth: "500px" }} />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="about-text">
                  <h2>
                    Discover the Latest Trends in Fashion at the
                    <b> Jaipur Fashion Expo 2024! </b>
                  </h2>
                  <p>
                    As part of our commitment to promoting the best in fashion,
                    we organize spectacular fashion expos that showcase the
                    diversity and creativity of our members. These events are
                    not just platforms for exhibiting the latest trends; they
                    are celebrations of craftsmanship, cultural influences, and
                    the evolving landscape of fashion. Through these expos, we
                    aim to connect manufacturers, designers, and fashion
                    enthusiasts, fostering a sense of community within the
                    industry.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      */}

      {/* owl crousel section start */}
      {/* owl crousel section end */}
      {/* <!-- new section start --> */}
      {/* <section className="section-padding pt-0">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-md-6">
              <div className="members-tabs">
                <h3>Mr. Chandiram</h3>
                <h5>Patron</h5>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="members-tabs">
                <h3>Mr. Keshav Shukla</h3>
                <h5>Vice President</h5>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="members-tabs">
                <h3>Mr. Siddharth Jain</h3>
                <h5>Joint Secretary</h5>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="members-tabs">
                <h3>Mr. Amit Jindal</h3>
                <h5>Treasurer</h5>
              </div>
            </div>
          </div>
          <div className="row mt-3">
            <h2 className="text-center mb-3">Executive Members</h2>
            <div className="col-lg-3 col-md-6">
              <div className="members-tabs">
                <h3 className="mb-0">
                  Mr. Shankar <br />
                  Joshi{" "}
                </h3>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="members-tabs">
                <h3 className="mb-0">Mr. Dheeraj Baplawat </h3>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="members-tabs">
                <h3 className="mb-0">Mr. Ajay Singh Dhakad </h3>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="members-tabs">
                <h3 className="mb-0">Mr. Manish Kumar Garg </h3>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      {/* <div className="fashion-section-bg">
        <section id="participants">
          <div className="container">
            <div className="row">
              <div className="col-md-6 p-0">
                <div className="fashion-text py-5">
                  <h3 className="text-white">
                    Creativity, and Innovation of the fashion industry
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div> */}
      {/* <!-- new section start --> */}
      <div className="upcoming-events-bg">
        <section className="">
          <div className="row m-0">
            {/* <div className="col-lg-6">
              <div className="container">
                <div className="upcoming-event-text text-white">
                  <h2>
                    Upcoming Event
                    Jaipur Fashion Expo
                    <b>
                      <span> 2024 </span>
                    </b>
                  </h2>
                  <p className="upcoming-paragraph">
                    As part of our commitment to promoting the best in fashion,
                    we organize spectacular fashion expos that showcase the
                    diversity and creativity of our members. These events are
                    not just platforms for exhibiting the latest trends; they
                    are celebrations of craftsmanship, cultural influences, and
                    the evolving landscape of fashion. Through these expos, we
                    aim to connect manufacturers, designers, and fashion
                    enthusiasts, fostering a sense of community within the
                    industry.
                  </p>
                  <h3>
                    <span> Date: </span>10th & 11th January, 2024
                  </h3> 
                   <p>
                    <span className="opacity-0"> Date: </span>11/Jan/2024
                  </p> 
                 <p>Time: 00:00 to 00:00</p>
                  <h3>
                    Venue: JECC, RIICO Industrial Area, Sitapura, Sanganer,
                    Jaipur
                  </h3> 
                </div>
              </div>
            </div> */}
            <div className="col-lg-12 bg-white">
              {/* <div className="upcoming-event-img">
                  <img src={UpcomingEvents} />
                </div> */}
              <div className="container">
                <div className="upcoming-event-text text-center">
                  <h2>Join Us</h2>
                  <p
                    className="upcoming-paragraph black"
                    style={{ color: "#000" }}
                  >
                    Whether you're an established brand, an up-and-coming
                    designer, or an enthusiast with a love for fashion, Jaipur
                    Garments Club welcomes you. Join us in shaping the future of
                    the garment industry, where tradition and innovation coexist
                    harmoniously. Step into the world of Jaipur Garments Club—a
                    place where the threads of tradition weave seamlessly with
                    the fabrics of the future. Explore, connect, and be part of
                    a community that is redefining the very fabric of fashion.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      {/* <!-- new section start --> */}
      <section className="section-padding about pt-0">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="about-us-section">
                <h2 className="mb-3">About Jaipur Garments Club</h2>
                <p className="about-content">
                  Welcome to Jaipur Garments Club, where tradition meets trendsetting, and fashion is not just a statement but a celebration of craftsmanship. Established by a collective of top manufacturers, creators and fashion brands, Jaipur Garments Club is more than a committee—it's a vibrant community dedicated to shaping the future of the textile and fashion industry.At present we are a group of more than 100 top manufacturers and creators.
                </p>
                <div className={`our-story-div ${className}`}>
                  <div className="row">
                    <h2>Our Story</h2>
                    <p className="about-content">
                      In the heart of the Pink City, Jaipur Garments Club was
                      born out of a shared passion for quality, creativity, and
                      a commitment to showcasing the rich heritage of Indian
                      textiles. We bring together the most influential names in
                      the garment industry, creating a collaborative space where
                      ideas flourish, and innovation takes center stage.
                    </p>
                    <p className="about-content">
                      Welcome to a world where every thread weaves a story, and
                      every garment showcase the inherited rich culture and
                      heritage. Our story begins centuries ago, in the royal
                      courts of Jaipur, where garments were not just pieces of
                      clothing but canvases of art. The vibrant hues,
                      embroidery, and luxurious fabrics spoke volumes about the
                      grandeur and opulence of the kings' courts. At JGC, we pay
                      special honor to this rich heritage by bringing forth
                      garments that seamlessly blend the grace of tradition with
                      the allure of modernity. Inspired by the local artists who
                      have adorned Jaipur with their creativity for generations,
                      our collections are a testament to the craftsmanship that
                      transcends time. From the vibrant colors of traditional
                      Bandhani to the delicate beauty of gotta patti work, to
                      Ajrak, Dabu printing, Bagru printing, Shibori tie-dye each
                      piece is a masterpiece that narrates the tales of skilled
                      hands and artistic hearts. We started by collaborating
                      with these local artisans, ensuring that every creation
                      was a canvas where tradition meets contemporary aesthetics
                      and look where the growth has taken us today!
                    </p>
                  </div>
                </div>
                <Button className="regis" onClick={toggleClass}>
                  {buttonText}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- new section start --> */}

      <div className="footer-bg bg-light">
        <footer>
          <h2>Contact Us</h2>
          <img src={MainLogo} className="organisers" alt="" />
          {/* <h5 className="mb-2">
            FOR BOOTH BOOKINGS CALL :
            <a href="tel:+91-9929336236"> +91-9929336236 </a>
            {/* <a href="tel:+91-9887063777">+91-9887063777</a>
          </h5> */}
          {/* <h5>
            OFFICE CONTACT NO.:
            <a href="tel:+91-9929
            
            336236"> +91-9929336236 </a>
          </h5> */}
          <h5>
            Email :<a href="mailto:@gmail.com"> info@jgcjaipur.com</a>
          </h5>
          <div>
            <h5 className="mt-2 mb-2">
              Reg.Office Address : 4/95,SFS,Agarwal Farm,Mansarovar,302020
            </h5>
            <h5 className="mb-2">
              Telephone No :<a href="tel:0141-3162059"> 0141-3162059 </a>
              {/* <a href="tel:+91-9887063777">+91-9887063777</a> */}
            </h5>
            <h5 className="mb-2">
              JGC MANAGER <br />
              Santosh Deshwal <a href="tel:+91-9887063777"> +91-9887063777 , </a>
              <a href="tel:+91-9929336236"> +91-9929336236 </a>
              {/* <a href="tel:+91-9887063777">+91-9887063777</a> */}
            </h5>
            <h5 className="mb-0">
              ⓒ2023 All Rights Reserved. Organised by Jaipur Graments Club
              (J.G.C)
            </h5>
          </div>
        </footer>
      </div>
      <>
        <Modal size="xl" className="company-list" show={companyshow} onHide={handleCompanyClose}>
          <Modal.Header closeButton>
            <Modal.Title>JGC Registered Company</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="table-responsive" style={{ maxHeight: '400px' }}>
              <table className="table align-middle" responsive>
                <thead>
                  <tr>
                    <th>SR.NO.</th>
                    <th>
                      NAME OF FIRM
                    </th>
                    <th>
                      CONTACT PERSON
                    </th>
                    <th>
                      CONTACT NO
                    </th>
                    {/* <th>
                      ADDRESS
                    </th> */}
                  </tr>
                </thead>
                <tbody>
                  {companydata.map((item, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{item.name}</td>
                      <td>{item.person}</td>
                      <td>{item.phoneNumber}</td>
                      {/* <td>{item.caddress}</td> */}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </Modal.Body>
        </Modal>
        <Modal size="xl"
          className="agents-list"
          show={agentsshow}
          onHide={handleAgentsClose}>
          <Modal.Header closeButton>
            <Modal.Title>JGC Registered Agents</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="table-responsive" style={{ maxHeight: '400px' }}>
              <table className="table align-middle">
                <thead>
                  <tr>
                    <th>SR.NO.</th>
                    <th>
                      NAME OF FIRM
                    </th>
                    <th>
                      CONTACT PERSON
                    </th>
                    <th>
                      CONTACT NO
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {agentdata.map((item, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{item.name}</td>
                      <td>{item.person}</td>
                      <td>{item.phoneNumber}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </Modal.Body>
          {/* <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button variant="primary" onClick={handleClose}>
              Save Changes
            </Button>
          </Modal.Footer> */}
        </Modal>
        <Modal
          size="lg"
          show={show}
          onHide={handleClose}
          centered
          backdrop="static"
        >
          <Modal.Header>
            <Modal.Title>
              <img src={MainLogo} alt="" style={{ maxWidth: "35px" }} />{" "}
              Registration
            </Modal.Title>
            <Button variant="outline-secondary" onClick={handleClose}>
              Close
            </Button>
          </Modal.Header>
          <Modal.Body className="mb-3 ">
            <div className="row">
              <div className="col-lg-6 col-12 regis-form-group">
                <label className="fw-bold">
                  Name <font color="#FF0000">*</font>
                </label>
                <input
                  type="text"
                  placeholder="Name"
                  className="form-control my-2"
                  onChange={(e) => {
                    handleContactPersonChange(e);
                  }}
                />
              </div>

              <div className="col-lg-6 col-12 regis-form-group">
                <label className="fw-bold">
                  Mobile No. <font color="#FF0000">*</font>
                </label>
                <input
                  type="text"
                  value={number}
                  placeholder="Mobile"
                  className="form-control my-2"
                  maxLength="10"
                  onChange={handleMobileChange}
                />
                <span style={{ color: "red" }}>{mobileError}</span>
              </div>

              <div className="col-lg-6 col-12 regis-form-group">
                <label className="fw-bold">Email</label>
                <input
                  type="text"
                  placeholder="Email"
                  className="form-control my-2"
                  onChange={(e) => handleEmailChange(e)}
                />
              </div>

              <div className="col-lg-6 col-12 regis-form-group">
                <label className="fw-bold">
                  Company Name <font color="#FF0000">*</font>
                </label>
                <input
                  type="text"
                  placeholder="Company Name"
                  className="form-control my-2"
                  onChange={(e) => {
                    setCompanyName(e.target.value);
                  }}
                />
              </div>

              <div className="col-lg-6 col-12 regis-form-group">
                <label className="fw-bold">Address</label>
                <input
                  type="text"
                  placeholder="Address"
                  className="form-control my-2"
                  onChange={(e) => {
                    setAddress(e.target.value);
                  }}
                />
              </div>
              <div className="col-lg-6 col-12 regis-form-group">
                <label className="fw-bold">
                  City <font color="#FF0000">*</font>
                </label>
                <input
                  type="text"
                  placeholder="City"
                  className="form-control my-2"
                  onChange={(e) => {
                    handleCity(e);
                  }}
                />
              </div>
              <div className="col-lg-6 col-12 regis-form-group">
                <label className="fw-bold">
                  Upload Pic <font color="#FF0000">*</font>
                </label>
                <input
                  type="file"
                  className="form-control my-2"
                  accept=".jpg, .jpeg, .png"
                  onChange={handlePhoto}
                />
              </div>
              <div className="col-lg-6 col-12 regis-form-group">
                <label className="fw-bold">
                  Upload ID Front <font color="#FF0000">*</font>
                </label>
                <input
                  type="file"
                  id="idfront"
                  className="form-control my-2"
                  accept=".jpg, .jpeg, .png"
                  onChange={handleIDFront}
                />
              </div>
              <div className="col-lg-6 col-12 regis-form-group mb-3">
                <label className="fw-bold">
                  Upload ID Back <font color="#FF0000">*</font>
                </label>
                <input
                  type="file"
                  id="idback"
                  className="form-control my-2"
                  onChange={handleIDBack}
                  accept=".jpg, .jpeg, .png"
                />
              </div>
              <div className="col-12 form-group mb-3">
                <Button
                  type="button"
                  className="regis w-100"
                  onClick={handleSubmit}
                  disabled={isLoading}
                >
                  {isLoading ? (
                    <Spinner animation="border" size="sm" />
                  ) : (
                    "Register"
                  )}
                </Button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </>

      {successMessage && (
        <>
          <Modal
            size="md"
            show={() => setSuccessMessage(true)}
            onHide={() => setSuccessMessage(false)}
            centered
            backdrop="static"
          >
            <Modal.Header>
              <Modal.Title>
                <img src={MainLogo} alt="" style={{ maxWidth: "35px" }} /> Form
                Submited Successfully
              </Modal.Title>
              <Button
                variant="outline-secondary"
                onClick={() => setSuccessMessage(false)}
              >
                Close
              </Button>
            </Modal.Header>

            <Modal.Body className="regis-greetings-bg">
              {visitorListData && (
                <>
                  <div className="register-greetings ps-5 pe-2 pb-4 pt-2">
                    <span>
                      Jaipur Garment Club (JGC) Registration Confirmation
                    </span>
                    <span>
                      Dear <b>{visitorName}</b>,
                    </span>

                    <div className="register-details">
                      <span className="text-decoration-underline mb-4">
                        Here are your Registration details:{" "}
                      </span>
                      <span>
                        User Name:
                        <b> {visitorName}</b>
                      </span>
                      <span>
                        Registration Number: <b> {visitorListData}</b>
                      </span>
                      <span>
                        Registration Date: <b> {registrationDate}</b>
                      </span>
                    </div>
                    <span className="mt-3">Best regards,</span>
                    <span>The Jaipur Garment Club Team</span>
                  </div>
                </>
              )}
            </Modal.Body>
          </Modal>
        </>
      )}
    </>
  );
};

export default MainPage;