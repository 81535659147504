import React from 'react';
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import Footer from "../superAdmin/footer/Footer";
import MainLogo from "../../assets/image/jgc_logo.png";

const ThankYou = () => {
  const navigate = useNavigate()
  return (
    <>
      <div className="container mt-5 ">
        <div className="text-center my-5">
          <img
            src={MainLogo}
            className="img-fluid"
            alt="Main Logo"
            style={{ height: "200px" }}
          /></div>
        <div className="row justify-content-center mt-5">
          <div className="col-md-8 text-center">
            <h1 className="display-4 text-success">Thank You for Registering!</h1>
            {/* <p className="lead">Hello, <strong>{name}</strong>!</p> */}
            <p>Your registration was successful.</p>
            <p>In regards: <strong>{process.env.REACT_APP_FULL_SHOW_NAME}</strong></p>
            <div className="mt-4">
              <Button variant="btn btn-outline-secondary" className="me-5" onClick={() => navigate("/")}>Go to Homepage</Button>
              <Button variant="btn btn-outline-secondary" className="ms-5" onClick={() => window.location.reload(true)}>Fill Others Detail</Button>

            </div>
          </div>
        </div>

      </div>
      <Footer />
    </>
  );
};

export default ThankYou;
