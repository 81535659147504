import React, { useState, useEffect } from "react";
import Logo from "../assets/image/jgc_logo.png";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { message } from "antd";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import Footer from "../components/superAdmin/footer/Footer";

function CommonLogin({ header, afterLogin, loginFailed, prem_name }) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleChange();
    }
  };
  useEffect(() => {
    if (localStorage.getItem("jff_token")) {
      axios
        .get(
          `${
            process.env.REACT_APP_API_END_POINT
          }/check-authenticity/${localStorage.getItem("jff_token")}`
        )
        .then((resData) => {
          if (
            resData.data.status &&
            resData.data.permission_names.includes(prem_name)
          ) {
            navigate(afterLogin);
          } else {
            navigate(loginFailed);
          }
        });
    }
  }, []);

  const handleChange = () => {
    setIsLoading(true);
    if (!email) {
      message.error("Enter username or email");
      setIsLoading(false);
      return;
    }
    if (!password) {
      message.error("Enter password");
      setIsLoading(false);
      return;
    } else {
      axios
        .post(`${process.env.REACT_APP_API_END_POINT}/login`, {
          email,
          password,
        })
        .then((response) => {
          localStorage.setItem(
            "permission",
            JSON.stringify(response.data.category.permissions)
          );
          localStorage.setItem("jff_token", response.data.token);
          localStorage.setItem("user_id", response.data.category._id);
          setIsLoading(false);
        })
        .catch((error) => {
          setIsLoading(false);
          console.log(error);
        })
        .finally(() => {
          let data = localStorage.getItem("permission");
          data = JSON.parse(data);

          console.log("data", data);
          let nameArray = [];

          axios
            .post(
              `${process.env.REACT_APP_API_END_POINT}/get-permission-name`,
              {
                permission: data,
              }
            )
            .then((res) => {
              // console.log(".data.data", res.data.data);
              nameArray = res.data.data;

              if (nameArray.includes(prem_name)) {
                // console.log("running inside if");
                navigate(afterLogin);
              } else {
                message.error("Not Authorised");
              }
            });
        });
    }
  };

  return (
    <>
      <div className="container">
        <img
          src={Logo}
          alt="logo-img"
          className="my-1 mx-auto d-flex"
          style={{
            height: "230px",
            width: "230px",
            marginTop: "40px !important",
          }}
        />

        <div className="exhibitorlogin">
          <div className="exhibitorloginbody">
            <div className="fw-semi-bold fs-3">{header}</div>
            <Form className="my-4">
              <Form.Control
                type="username"
                id="inputUsename"
                aria-describedby="passwordHelpBlock"
                placeholder="Username"
                className="my-4"
                required
                onChange={(e) => setEmail(e.target.value)}
                onKeyDown={handleKeyDown}
              />

              <Form.Control
                type="password"
                id="inputPassword5"
                aria-describedby="passwordHelpBlock"
                placeholder="Password"
                required
                onChange={(e) => setPassword(e.target.value)}
                onKeyDown={handleKeyDown}
              />

              <Button className="my-4 btn-block" onClick={handleChange}>
                {isLoading ? <Spinner animation="border" size="sm" /> : "Login"}
              </Button>
            </Form>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default CommonLogin;
