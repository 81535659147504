import React, { useState, useEffect } from "react";
import Logo from "../../../assets/image/jgc_logo.png";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { message } from "antd";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Spinner } from "react-bootstrap";
const capitalizeFirstLetter = (string) => {
  if (!string) return "";
  return string.charAt(0).toUpperCase() + string.slice(1);
};

function SuperAdminAreaLogin() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
   const pathname = window.location.pathname;
   const userType = pathname.split("/")[1];
   console.log("helllo i am here ", capitalizeFirstLetter(userType));
   localStorage.setItem(
     "exhibitor_agent_type",
     capitalizeFirstLetter(userType)
   );

 const exhibitorAgentType = localStorage.getItem("exhibitor_agent_type");


  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleChange();
    }
  };

  useEffect(() => {
    if (localStorage.getItem("jff_token")) {
      navigate(`/${exhibitorAgentType.toLowerCase()}/dashboard`);
    }
  }, []);

  const handleChange = () => {
    setIsLoading(true);
    if (!email) {
      message.error("Enter username or email");
      setIsLoading(false);
      return;
    }
    if (!password) {
      message.error("Enter password");
      setIsLoading(false);

      return;
    } else {
      axios
        .post(`${process.env.REACT_APP_API_END_POINT}/login`, {
          email,
          password,
        })
        .then((response) => {
          // insert token in localstorage
          localStorage.setItem(
            "permission",
            JSON.stringify(response.data.category.permissions)
          );
          localStorage.setItem("jff_token", response.data.token);
          localStorage.setItem("user_id", response.data.category._id);
          localStorage.setItem(
            "type_of_user",
            `/${exhibitorAgentType.toLowerCase()}/login`
          );
          setIsLoading(false);
        })
        .catch((error) => {
          setIsLoading(false);
          console.log(error);
        })
        .finally(() => {
          let data = localStorage.getItem("permission");
          data = JSON.parse(data);

          console.log("data", data);

          let nameArray = [];

          // console.log("ssdsssasdasa",data[i])
          axios
            .post(
              `${process.env.REACT_APP_API_END_POINT}/get-permission-name`,
              {
                permission: data,
              }
            )
            .then((res) => {
              console.log(".data.data", res.data.data);
              nameArray = res.data.data;

              if (nameArray.includes("exhibitor")) {
                console.log("running inside if");
                navigate(`/${exhibitorAgentType.toLowerCase()}/dashboard`);
              } else {
                message.error("Access Denied");
              }
            });
        });
    }
  };

  return (
    <>
      <div className="container">
        <img
          src={Logo}
          alt="logo-img"
          className="my-1 mx-auto d-flex"
          style={{ height: "200px", width: "200px" }}
        />

        <div className="exhibitorlogin">
          <div className="exhibitorloginbody">
            <div className="fw-semi-bold fs-3">
              Welcome to {localStorage.getItem('exhibitor_agent_type')} login
            </div>
            <Form className="my-4">
              <Form.Control
                type="username"
                placeholder="Username"
                className="my-4"
                onChange={(e) => setEmail(e.target.value)}
                required
                onKeyDown={handleKeyDown}
              />

              <Form.Control
                type="password"
                id="inputPassword5"
                aria-describedby="passwordHelpBlock"
                placeholder="Password"
                onChange={(e) => setPassword(e.target.value)}
                required
                onKeyDown={handleKeyDown}
              />

              <Button className="my-4 btn-block" onClick={handleChange}>
                {isLoading ? <Spinner animation="border" size="sm" /> : "Login"}
              </Button>
            </Form>
          </div>
        </div>
      </div>
    </>
  );
}

export default SuperAdminAreaLogin;
