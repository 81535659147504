import React, { useCallback, useEffect, useRef, useState } from "react";
import image_demo from "./images/print_demo.png";
import axios from "axios";
import html2canvas from "html2canvas";
import Barcode from "react-barcode";
import { useParams, useLocation } from "react-router-dom";
import logo from "../../assets/image/jgc_logo.png"
import backgroundImage from "../../assets/image/Jaipur Garment Club.png"
import dummyImage from '../../assets/image/a.png'
import jsPDF from 'jspdf';
import './bodycs.css'
import moment from 'moment'
import { Button } from "react-bootstrap";
const Ecard = () => {
    const printRef = useRef();
    const [image, setImage] = useState("");
    const [name, setName] = useState("");
    const [city, setCity] = useState("");
    const [visitor, setVisitor] = useState("");
    const [loading, setLoding] = useState(false)
    // const { barcode } = useParams();
    const [showError, setShowError] = useState(false);
    const [inputValue, setInputValue] = useState("");
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    // Get the value of the 'q' parameter
    const barcode = params.get('q');
    useEffect(() => {

        axios.get(`${process.env.REACT_APP_API_END_POINT}/visitor-detail-by-barcode/${barcode}`).then((resData) => {
            console.log("resData.data", resData.data)
            const { visitor_name, city, visiting_as, photo } = resData.data.data
            setImage(photo)
            setInputValue(barcode)
            setName(visitor_name)
            setCity(city)
            setVisitor(visiting_as)


        }).catch((err) => {
            setShowError(true)

        })
    }, []);

    const handleScreenshot = async () => {
        setLoding(true)
        const input = printRef.current;
        html2canvas(input)
            .then((canvas) => {
                const imgData = canvas.toDataURL('image/png');
                const pdf = new jsPDF('p', 'mm', 'a4');
                const imgWidth = 210;
                const pageHeight = 297;
                const imgHeight = (canvas.height * imgWidth) / canvas.width;
                let heightLeft = imgHeight;
                let position = 0;

                pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
                heightLeft -= pageHeight;

                while (heightLeft >= 0) {
                    position = heightLeft - imgHeight;
                    pdf.addPage();
                    pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
                    heightLeft -= pageHeight;
                }

                pdf.save(`Jgc E_pass ${name}.pdf`);
                setLoding(false)

            })
            .catch((err) => console.log("asdasd", err));
        // const input = printRef.current;
        // html2canvas(input)
        //     .then((canvas) => {
        //         const imgData = canvas.toDataURL('image/png');
        //         const pdf = new jsPDF('p', 'mm', 'a4');
        //         const imgWidth = 210;
        //         const pageHeight = 297;
        //         const imgHeight = (canvas.height * imgWidth) / canvas.width;
        //         let heightLeft = imgHeight;
        //         let position = 0;

        //         pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
        //         heightLeft -= pageHeight;

        //         while (heightLeft >= 0) {
        //             position = heightLeft - imgHeight;
        //             pdf.addPage();
        //             pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
        //             heightLeft -= pageHeight;
        //         }

        //         pdf.save(`Aadikaash-invoice-00${name}.pdf`);
        //         setLoding(false)

        //     })
        //     .catch((err) => {
        //         console.log("err", err)

        //         setShowError(true)

        //     });
    };

    return (
        <>
            <div className="container-fluid">
                <div className="row vh-100 my-auto etc">
                    {/* Image Section - 65% width */}
                    <div className="col-lg-7 col-md-12   d-lg-block p-0 img-col" style={{ position: 'fixed' }}>
                        <img
                            src={backgroundImage}
                            alt="Placeholder"
                            className="img-fluid vh-100 w-100 top-img"
                            style={{ objectFit: 'cover' }}
                        />
                    </div>
                    <div className="col-lg-5 col-md-12" style={{ position: 'absolute', right: '0px' }}>
                        <div className="form-card-section">
                            <form action="">
                                <div className="form-header">
                                    {/* <h3 style={{ color: 'white' }}>{"text"}</h3> */}
                                </div>
                                {showError ? <>
                                    <div className="container h-100 mt-5">
                                        <div className="row h-100 justify-content-center align-items-center">
                                            <div >
                                                <div className="card shadow">
                                                    <div className="card-body text-center">
                                                        <h2 className="card-title mb-4">
                                                            This Barcode does not exist!
                                                        </h2>
                                                        {/* <div className="checkmark-container">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="120"
                  height="120"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="green"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="bi bi-check-circle"
                >
                  <circle cx="12" cy="12" r="10" />
                  <path d="M9 12l2 2 4-4" />
                </svg>
              </div> */}
                                                        <p className="card-text mt-4">{process.env.REACT_APP_FULL_SHOW_NAME}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </> : (
                                    <>
                                        <div ref={printRef}>

                                            <div className="card-image"
                                                style={{
                                                    position: 'absolute',
                                                    zIndex: '9',
                                                    left: '0px',
                                                    right: '0px',
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                }}
                                            >
                                                <img src={logo} alt=""

                                                className="logo-of-event"
                                                   
                                                />

                                            </div>
                                            <div className="form-row" style={{
                                                display: 'flex', justifyContent: 'center',

                                                height: '100vh',
                                                alignItems: 'center'

                                            }} >
                                                <div id="printable-content" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                    <div className="imge-section">
                                                        <img src={image ? `${process.env.REACT_APP_IMAGE_URL}/photo/${image
                                                            }?v=${moment().unix()}` : dummyImage} style={{
                                                                margin: '0 auto',
                                                                position: 'absolute',
                                                                top: '190px',
                                                                height: '110px',
                                                                width: '100px',
                                                                left: '0px',
                                                                right: '0px',
                                                                objectFit: 'contain'
                                                            }} alt="" />
                                                        <div className="id-text" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', position: 'absolute', left: '0px', right: 0, bottom: '150px' }}>
                                                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                                                <h3 className="mt-1" style={{ fontSize: '12px', color: '#000' }}>{name}</h3>
                                                            </div>
                                                            <span style={{ fontSize: '12px', color: '#000' }}>{city}</span>
                                                            <div style={{ height: '10px' }}>
                                                                {inputValue && (
                                                                    <Barcode
                                                                        style={{ background: "#ffffff" }}
                                                                        value={inputValue}
                                                                        format="CODE128"
                                                                        width={2}
                                                                        height={35}
                                                                        displayValue={true}
                                                                    />
                                                                )}
                                                                <div className="text-center">
                                                                    <h4 className="mt-3" style={{ color: '#000', fontSize: '20px', textAlign: 'center' }}>{visitor}</h4>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-row">
                                            <div className="container" style={{ textAlign: 'center' }}>
                                                {/* <div className="card bg-light p-4 text-center m-2"> */}


                                                <Button style={{ color: '#000', textAlign: 'center', cursor: 'pointer', width: '200px' }}

                                                    variant="btn btn-outline-secondary"
                                                    onClick={handleScreenshot}>{loading ? <div class="spinner-border spinner-border-sm" role="status">
                                                        <span class="sr-only">Loading...</span>
                                                    </div> : 'Take A Screenshot'}</Button>

                                                {/* </div> */}
                                            </div>
                                        </div>
                                    </>
                                )}
                            </form>
                        </div>
                    </div>
                </div>
            </div>


        </>
    );
};

export default Ecard;