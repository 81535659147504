import React, { useEffect, useState } from "react";
import { MdOutlineFlight } from "react-icons/md";
import { AiOutlineFileImage } from "react-icons/ai";
import SubAdminNavbar from "./SubAdminNavbar";
import axios from "axios";
import "jspdf-autotable";
import Footer from "../superAdmin/footer/Footer";
import Table from "react-bootstrap/Table";
import moment from "moment";
import { Button, Modal, Spinner } from "react-bootstrap";
import Pagination from "../../pagination/Pagination";
import LoginRedirection from "../Service/LoginRedirection";
import TokenUndefined from "../Service/TokenUndefined";
import { useNavigate } from "react-router-dom";
import { formatDateTime } from "../../common/DateTimeFormate";
import CommonShowDetails from "../../common/CommonShowDetails";
import { BsFillEyeFill } from "react-icons/bs";


const AdminInvitedVIPGuestList = () => {
  const [vipGuestList, setVipGuestList] = useState([]);
  const [editArea, setEditArea] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [modalName, setModalName] = useState("");
  const [booking_date, setBooking_date] = useState("");
  const [room_No, setRoom_No] = useState("");
  const [room_allocation_time, setRoom_allocation_time] = useState("");
  const [hotel_allocation_time, setHotel_allocation_time] = useState("");
  const [hotel_name, setHotel_name] = useState("");
  const [cab_detail, setcab_detail] = useState([]);
  const [hotelDetail, sethotelDetail] = useState({});
  const [flight_details, setflight_details] = useState({});
  const [reload, setReload] = useState(false);
  const [totalLength, setTotalLength] = useState(0);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [dummyPage, setDummyPage] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const navigate = useNavigate();
  const [isHovered, setIsHovered] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [imageValue, setImageValue] = useState("");
  const [modalHeader, setModalHeader] = useState("");
  const [loadingState, setloadingState] = useState(false);
  const [remarks, setRemarks] = useState(false);
  const [remarksValue, setRemarksValue] = useState("");
  const [editID, setEditID] = useState();
  const [showAllDetails, setShowAllDetails] = useState(false)
  const [dataShowDetail, setDataShowDetail] = useState(null)
  useEffect(() => {
    TokenUndefined("adminvip/login");
    axios
      .get(`${process.env.REACT_APP_API_END_POINT}/invited-vip-guest-list`, {
        headers: {
          token: localStorage.getItem("jff_token"),
        },
        params: {
          page: dummyPage,
          limit: limit,
          query: searchQuery,
        },
      })
      .then((res) => {
        LoginRedirection(res, "adminvip/login");
        const filteredData = res.data.data.filter(item => !(item.mode_set_by_admin === 1));
        setVipGuestList(filteredData);
        setloadingState(true);

        // pagination
        setTotalLength(res.data.length);
        setLimit(res.data.limit);
        setPage(res.data.pageNo);
      })
      .catch((err) => {
        if (err.response.status === 401) {
          navigate("/adminvip/login");
          localStorage.removeItem("jff_token");
          localStorage.removeItem("category");
          localStorage.removeItem("permission");
          localStorage.removeItem("user_id");
          localStorage.removeItem("type_of_user");
        }
      });
  }, [reload, dummyPage, searchQuery]);

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
    setDummyPage(1);
  };

  const handleRemarks = () => {
    TokenUndefined("superadmin/login");

    axios
      .post(
        `${process.env.REACT_APP_API_END_POINT}/update-remarks-value`,
        {
          id: editID,
          remarks_value: remarksValue,
        },
        {
          headers: {
            token: localStorage.getItem("jff_token"),
          },
        }
      )
      .then((res) => {
        console.log("drtghjokploigyujok", res.data);
        LoginRedirection(res, "superadmin/login");
        setRemarksValue("");
        setRemarks(false);
        setReload(!reload);
      })
      .catch((err) => {
        // LoginRedirection("/superadmin/login");
        if (err.response.status === 401) {
          navigate("/superadmin/login");
          localStorage.removeItem("jff_token");
          localStorage.removeItem("category");
          localStorage.removeItem("permission");
          localStorage.removeItem("user_id");
          localStorage.removeItem("type_of_user");
        }
      });
  };

  const downloadAdminVipRequestList = () => {
    axios
      .get(
        `${process.env.REACT_APP_API_END_POINT
        }/download-admin-vip-request-data/${localStorage.getItem("user_id")}`
      )
      .then((res) => {
        console.log("data is exported success Fully");
        const link = `${process.env.REACT_APP_API_END_POINT
          }/download-admin-vip-request-data/${localStorage.getItem("user_id")}`;
        window.location.href = link;
      });
  };

  return (
    <>
      <CommonShowDetails showAllDetails={showAllDetails} setShowAllDetails={setShowAllDetails} data={dataShowDetail} travel_data={true} />

      {remarks && (
        <>
          {/*         
          <Modal
            show={remarks}
            onHide={() => setRemarks(false)}
            animation={false}
            centered
            backdrop="static"
          >
            <Modal.Header closeButton>
              <Modal.Title>Remarks</Modal.Title>
            </Modal.Header>
            <Modal.Body>{remarksValue}</Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={() => setRemarks(false)}>
                Close
              </Button>
             
            </Modal.Footer>
          </Modal> */}

          <Modal
            show={remarks}
            onHide={() => setRemarks(false)}
            animation={false}
            centered
            backdrop="static"
          >
            <Modal.Header closeButton>
              <Modal.Title>Remarks</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <textarea
                style={{
                  width: "100%",
                  minHeight: "200px",
                  resize: "none",
                  padding: "10px",
                  border: "solid 1px #ccc",
                  borderRadius: "10px",
                }}
                onChange={(e) => setRemarksValue(e.target.value)}
                value={remarksValue}
              ></textarea>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={() => setRemarks(false)}>
                Close
              </Button>
              <Button
                variant="primary"
                onClick={() => {
                  handleRemarks();
                }}
              >
                Save Changes
              </Button>
            </Modal.Footer>
          </Modal>
        </>
      )}

      {isHovered && (
        <div className="">
          <Modal
            size="xl"
            show={isModalVisible}
            onHide={() => setIsModalVisible(false)}
            animation={false}
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title>{modalHeader}</Modal.Title>
            </Modal.Header>
            <Modal.Body className="mx-auto">
              {" "}
              <img alt="" src={imageValue} className="modal-img" />
            </Modal.Body>
          </Modal>
        </div>
      )}
      <SubAdminNavbar />
      <div className="container">
        <Modal
          show={showModal}
          onHide={() => setShowModal(false)}
          animation={false}
          backdrop="static"
          size="lg"
        >
          <Modal.Header closeButton>
            <Modal.Title className="text-center">
              <div className="fs-2 ">
                Hotel, Flight and Cab details of
                <span className="fw-bold">&nbsp;{modalName}</span>
              </div>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <span className="fw-bold fs-3">Flight Detail</span>
              <Table responsive className="table table-striped p-2 data-table">
                <thead>
                  <tr>
                    <th scope="col">Arrival Flight No.</th>
                    <th scope="col">Arrival Flight Date</th>
                    <th scope="col">Arrival Flight Time</th>
                    <th scope="col">Departure Flight No.</th>
                    <th scope="col">Departure Flight Date</th>
                    <th scope="col">Departure Flight Time</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      {flight_details.arrival_flight_no
                        ? flight_details.arrival_flight_no
                        : "_"}
                    </td>
                    <td>
                      {flight_details.arrival_date
                        ? flight_details.arrival_date
                        : "-"}
                    </td>
                    <td>
                      {flight_details.arrival_time
                        ? flight_details.arrival_time
                        : "-"}
                    </td>

                    <td>
                      {flight_details.departure_flight_no
                        ? flight_details.departure_flight_no
                        : "-"}
                    </td>
                    <td>
                      {flight_details.departure_date
                        ? flight_details.departure_date
                        : "-"}
                    </td>
                    <td>
                      {flight_details.departure_time
                        ? flight_details.departure_time
                        : "-"}
                    </td>
                  </tr>
                </tbody>
              </Table>
            </div>
            <hr />
            <div>
              <span className="fw-bold fs-3">Hotel Detail</span>
              <Table responsive className="table table-striped p-2 data-table">
                <thead>
                  <tr>
                    <th scope="col">Hotel Name:</th>
                    <th scope="col">Date for Booking:</th>
                    <th scope="col">Room No:</th>
                    {/* <th scope="col">Room Allotation Time: </th>
                    <th scope="col">Hotel Allotation Time: </th> */}
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      {hotelDetail.hotel_name ? hotelDetail.hotel_name : "-"}
                    </td>
                    <td>
                      {hotelDetail.booking_date
                        ? hotelDetail.booking_date
                        : "-"}
                    </td>
                    <td>{hotelDetail.room_no ? hotelDetail.room_no : "-"}</td>
                    {/* <td>
                      {hotelDetail.room_allotation_time
                        ? hotelDetail.room_allotation_time
                        : "-"}
                    </td>
                    <td>
                      {hotelDetail.hotel_allotation_time
                        ? hotelDetail.hotel_allotation_time
                        : "-"}
                    </td> */}
                  </tr>
                </tbody>
              </Table>
            </div>
            <hr />
            <div>
              <span className="fw-bold fs-3">Cab Detail</span>
              <Table
                responsive
                className="table table-striped p-2 data-table m-0"
              >
                <thead>
                  <tr>
                    <th scope="col-3" className="w-25">
                      Sr. No.
                    </th>
                    <th scope="col-3" className="w-25">
                      Route
                    </th>
                    <th scope="col-3" className="w-25">
                      cab Number
                    </th>
                    <th scope="col-3" className="w-25">
                      Booking time{" "}
                    </th>
                  </tr>
                </thead>
              </Table>
              <div
                className="nodesure"
                style={{ maxHeight: "150px", overflowY: "scroll" }}
              >
                <Table
                  responsive
                  className="table table-striped p-3 data-table"
                >
                  {/* <thead>
                    <tr>
                    <th scope="col-3" className="w-25">Sr. No.</th>
                      <th scope="col-3" className="w-25">Route</th>
                      <th scope="col-3" className="w-25">cab Number</th>
                      <th scope="col-3" className="w-25">Booking time </th>
                    </tr>
                  </thead> */}
                  <tbody>
                    {cab_detail.map((data, idx) => {
                      return (
                        <tr key={idx}>
                          <th scope="col-3" className="w-25">
                            {idx + 1}
                          </th>
                          <td className="w-25">{data.route}</td>
                          <td className="w-25">{data.cab_number}</td>
                          <td className="w-25">
                            {moment(data.booking_date).format("lll")}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer></Modal.Footer>
        </Modal>
      </div>
      {!editArea && (
        <div className="">
          <div className="text-center">
            <h3 className="form-heading">Invited Buyer List</h3>
          </div>

          {loadingState ? (
            <>
              <div className="d-flex justify-content-between mx-5 my-2">
                <Button
                  variant="btn btn-outline-secondary ms-3"
                  type="submit"
                  onClick={() => downloadAdminVipRequestList()}
                >
                  Download Excel
                </Button>
                <form className="d-flex" role="search">
                  <input
                    className="form-control me-2"
                    type="search"
                    placeholder="Search"
                    aria-label="Search"
                    value={searchQuery}
                    onChange={handleSearch}
                  />
                </form>
              </div>{" "}
              {vipGuestList.length > 0 ? (
                <>
                  {" "}
                  <Table
                    responsive
                    className="table table-striped my-3 p-3 data-table"
                  >
                    <thead>
                      <tr>
                        <th scope="col">Sr. No.</th>
                        <th scope="col">Reg. No.</th>
                        <th scope="col">Visitor Name</th>
                        <th scope="col">Company Name </th>
                        <th scope="col">Mobile No.</th>
                        <th scope="col">Gender</th>


                        {/* <th scope="col">Day</th> */}
                        <th scope="col">City</th>
                        <th scope="col">Invited By</th>
                        <th scope="col">Reg. Date </th>
                        <th scope="col">Image</th>
                        <th scope="col">ID Card</th>
                        <th scope="col">Preferred Date</th>
                        <th scope="col">Preferred Time</th>
                        <th scope="col">Preference</th>
                        {/* <th scope="col">Trip Type</th> */}
                        <th scope="col">Mode</th>
                        {/* <th scope="col">Download</th> */}
                        {/* <th scope="col">Info</th> */}
                        <th scope="col">Remarks</th>
                        <th scope="col">Details</th>

                      </tr>
                    </thead>
                    <tbody>
                      {vipGuestList?.map((data, idx) => {
                        return (
                          <tr key={idx}>
                            <th scope="row">{data?.new_sort_number}</th>
                            <td>{data.barcode}</td>
                            <td>{data.visitor_name}</td>
                            <td>{data.company_name}</td>
                            <td>{data.mobile_number}</td>
                            <td>{data?.gender}</td>

                            {/* <td>{data.visiting_day}</td> */}
                            <td>{data.city}</td>
                            <td>
                              {" "}
                              {data.companyData[0]
                                ? data.companyData[0].company_name
                                : "__"}
                            </td>
                            <td>
                              {moment(data.createdAt).format("MMM Do YY")}
                            </td>

                            <td>
                              {data.photo ? (
                                <>
                                  <div
                                    className={`img-size ${isHovered ? "image-hovered" : ""
                                      }`}
                                  >
                                    <img
                                      className="img-size"
                                      loading="lazy"
                                      src={`${process.env.REACT_APP_IMAGE_URL
                                        }/photo/${data.photo
                                        }?v=${moment().unix()}`}
                                      alt=""
                                      onerror="this.src='-'"
                                      onClick={() => {
                                        setImageValue(
                                          `${process.env.REACT_APP_IMAGE_URL
                                          }/photo/${data.photo
                                          }?v=${moment().unix()}`
                                        );
                                        setIsHovered(true);
                                        setIsModalVisible(true);
                                        setModalHeader(
                                          `${data.visitor_name} Photo`
                                        );
                                      }}
                                    />
                                  </div>
                                </>
                              ) : (
                                <span>-</span>
                              )}
                            </td>
                            <td>
                              <div className="d-flex">
                                {data.id_proof_front ? (
                                  <div className="front-id-proof">
                                    <div className="main-img">
                                      <img
                                        // className="img-size"
                                        loading="lazy"
                                        src={`${process.env.REACT_APP_IMAGE_URL
                                          }/id_proof/${data.id_proof_front
                                          }?v=${moment().unix()}`}
                                        alt=""
                                        onerror="this.src='-'"
                                        className={`img-size ${isHovered ? "image-zoom" : ""
                                          }`}
                                        onClick={() => {
                                          setImageValue(
                                            `${process.env.REACT_APP_IMAGE_URL
                                            }/id_proof/${data.id_proof_front
                                            }?v=${moment().unix()}`
                                          );
                                          setIsHovered(true);
                                          setIsModalVisible(true);
                                          setModalHeader(
                                            `${data.visitor_name} ID Proof Front`
                                          );
                                        }}
                                      />
                                    </div>
                                  </div>
                                ) : (
                                  <span>-</span>
                                )}
                                {data.id_proof_back ? (
                                  <div>
                                    <img
                                      // className="img-size"
                                      loading="lazy"
                                      src={`${process.env.REACT_APP_IMAGE_URL
                                        }/id_proof/${data.id_proof_back
                                        }?v=${moment().unix()}`}
                                      alt=""
                                      onerror="this.src='-'"
                                      className={`img-size ${isHovered ? "image-zoom" : ""
                                        }`}
                                      onClick={() => {
                                        setImageValue(
                                          `${process.env.REACT_APP_IMAGE_URL
                                          }/id_proof/${data.id_proof_back
                                          }?v=${moment().unix()}`
                                        );
                                        setIsHovered(true);
                                        setIsModalVisible(true);
                                        setModalHeader(
                                          `${data.visitor_name} ID Proof Back`
                                        );
                                      }}
                                    />
                                  </div>
                                ) : (
                                  <span>-</span>
                                )}
                              </div>
                            </td>
                            {/* <td>
                      <div className="pointer-cursor">
                        <MdOutlineFlight /> <AiOutlineFileImage />
                      </div>
                    </td> */}
                            {/* <td>
                              <Button
                                onClick={() => {
                                  setModalName(data.visitor_name);
                                  setShowModal(true);
                                  setBooking_date(data.booking_date);
                                  setRoom_No(data.room_no);
                                  setRoom_allocation_time(
                                    data.room_allotation_time
                                  );
                                  setHotel_allocation_time(
                                    data.hotel_allotation_time
                                  );
                                  setHotel_name(data.hotel_name);
                                  setcab_detail(data.cab_detail);
                                  sethotelDetail(data.hotel_detail);
                                  setflight_details(
                                    data.flight_details
                                      ? data.flight_details
                                      : "-"
                                  );
                                }}
                              >
                                Info
                              </Button>
                            </td> */}
                            <td>
                              {
                                data?.travel_detail?.arrival_date_time
                              }
                            </td>
                            <td>
                              {
                                data?.travel_detail?.departure_date_time ? data?.travel_detail?.departure_date_time : null
                              }
                            </td>
                            <td>
                              {data?.travel_detail?.preffered_train_flight}
                            </td>
                            {/* <td>
                              {data?.travel_detail?.travel_type
                                ? data?.travel_detail?.travel_type
                                : "_"}
                            </td> */}
                            <td>{data?.travel_detail?.travel_mode}</td>
                            <td>
                              <Button
                                variant="btn btn-outline-secondary ms-3"
                                onClick={() => {
                                  setRemarks(true);
                                  setRemarksValue(
                                    data.remarks_value ? data.remarks_value : ""
                                  );

                                  setEditID(data._id);
                                }}
                              >
                                Remarks
                              </Button>
                            </td>
                            <td style={{ textAlign: 'center' }}><BsFillEyeFill style={{ cursor: 'pointer' }} onClick={() => {
                              setShowAllDetails(true);
                              setDataShowDetail(data)
                            }} /></td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                  {!editArea && (
                    <div className="d-flex mx-2 justify-content-end">
                      <Pagination
                        dummyPage={setDummyPage}
                        totalLength={totalLength}
                        limit={limit}
                        pageNo={page}
                      />
                    </div>
                  )}
                </>
              ) : (
                <>
                  {" "}
                  <div className="no-data-div content-height">
                    <h5 className="no-data-heading">No Record Found</h5>
                  </div>
                </>
              )}
            </>
          ) : (
            <>
              {" "}
              <div className="content-height d-flex justify-content-center align-items-center">
                <Spinner animation="border" size="lg" />
              </div>
            </>
          )}
        </div>
      )}
      <Footer />
    </>
  );
};

export default AdminInvitedVIPGuestList;
