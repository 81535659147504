import React from "react";
import Container from "react-bootstrap/Container";
import Logo from "../../../assets/image/jgc_logo.png";
import { useNavigate  } from "react-router-dom";
// import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";


function SubAdminNavbar() {
    const navigate=useNavigate();
  return (
    <>
      <div className="d-flex">
        {" "}
        <img
          src={Logo}
          alt="logo-img"
          className="my-1 mx-5"
          style={{ height: "100px", width: "100px" }}
          onClick={() => navigate("/exhibitor")}
        />
        {/* <div className="d-flex mx-auto align-items-center">
          <span className="fw-bold fs-1">DWRIKA FASHION</span>
        </div> */}
      </div>
      <Navbar
        bg="dark"
        expand="lg"
        data-bs-theme="dark"
        className="bg-body-tertiary"
      >
        <Container>
          {/* <Navbar.Brand href="#home">Navbar</Navbar.Brand> */}
          <Navbar.Toggle aria-controls="navbarScroll" />
          <Navbar.Collapse id="navbarScroll">
            <Nav
              className="me-auto my-2 my-lg-0"
              // style={{ maxHeight: "100px" }}
              navbarScroll
            >
             
                {" "}
                <Nav.Link onClick={()=>navigate('/exhibitor/dashboard')}>Invited VIP Guest List</Nav.Link>
                <Nav.Link onClick={()=>navigate('/exhibitor/dashboard')}>Change Password</Nav.Link>
                <Nav.Link onClick={()=>navigate('/exhibitor/dashboard')}>Logout</Nav.Link>
             

            
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
}

export default SubAdminNavbar;
